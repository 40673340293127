import React from "react"

import "./HeaderSection.scss"

export default class HeaderSection extends React.Component {
  render() {
    return (
      <section className="how-we-work_header">
        <div className="container">
          <header className="header row">
            <h1 className="header-title col-lg-12 col-md-12 col-sm-12 col-xs-12">
              How we build ideas
            </h1>
          </header>
        </div>
      </section>
    )
  }
}
