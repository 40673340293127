import React from "react"

import KnowledgeScheme from "../../../images/HowWeWork/KnowledgeSection/knowledgeProduct.png"

import "./KnowledgeSection.scss"

export default class KnowledgeSection extends React.Component {
  render() {
    return (
      <section className="company-knowledge-section">
        <div className="knowledge-container">
          <div className="left-block">
            <p>
              <strong>Ideas</strong>
            </p>
            <p>
              <strong>Backlog</strong>
            </p>
            <p>
              <strong>Budget</strong>
            </p>
            <p>
              <strong>Quality</strong> requests
            </p>
            <p>
              <strong>Business</strong> development
            </p>
          </div>
          <div className="center-block">
            <h3>
              <strong>Domain</strong> knowledge
            </h3>
            <div>
              <img src={KnowledgeScheme} alt="Product" />
            </div>
            <h3>
              <strong>Technology</strong> expertise
            </h3>
          </div>
          <div className="right-block">
            <p>
              <strong>Design</strong>
            </p>
            <p>
              <strong>Scrum</strong> process
            </p>
            <p>
              <strong>Quality</strong> Assurance
            </p>
            <p>
              <strong>Project</strong> Management
            </p>
            <p>
              <strong>Development</strong> expertise
            </p>
          </div>
        </div>
      </section>
    )
  }
}
