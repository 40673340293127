import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import HeaderSection from "../components/HowWeWork/HeaderSection"
import AgileSection from "../components/HowWeWork/AgileSection"
import KnowledgeSection from "../components/HowWeWork/KnowledgeSection"
import ClientsSection from "../components/HowWeWork/ClientsSection"
import FlowSection from "../components/HowWeWork/FlowSection"
import { mainUrl } from "../js/config"

export default function HowWeWorkPage() {
  return (
    <section>
      <SEO
        title="Our Web & Software Development Flow — OS-System"
        description="OS-System’s web & software development services can take your business to the next level, reducing the gap between you and the heights of success you wish to achieve."
        canonical={`${mainUrl}/how-we-work/`}
      />
      <Layout showFormPoint={100}>
        <HeaderSection />
        <AgileSection />
        <KnowledgeSection />
        <ClientsSection />
        <FlowSection />
      </Layout>
    </section>
  )
}
