import React from "react"

import AgileScheme from "../../../images/HowWeWork/AgileSection/agile.png"

import "./AgileSection.scss"

const AgileSection = () => {
  return (
    <section className="how-we-work_agile">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3 className="how-we-work_agile_title">Agile Web Development</h3>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <img src={AgileScheme} alt="Agile Web Development" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AgileSection
