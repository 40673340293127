import React from 'react';
import { Link } from 'gatsby';

import Flow from '../../../images/HowWeWork/FlowSection/dev-flow.png';

import './FlowSection.scss';

export default class FlowSection extends React.Component {
  render() {
    return (
      <section className='company-flow-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-sm-12 col-md-12 col-xs-12'>
              <h3 className='company-flow-section__title'>
                Our development flow
              </h3>
            </div>
            <div className='col-lg-12 col-sm-12 col-md-12 col-xs-12'>
              <div className='company-flow-section__image-wrapper'>
                <div className='research'>
                  <div className='col-lg-6 col-sm-8 col-md-8 col-xs-6'>
                    <h4>Research</h4>
                    <p>Domain research Product vision statement</p>
                  </div>
                </div>
                <img src={Flow} alt='dev-flow' />
              </div>
            </div>
            <div className='col-lg-12 col-sm-12 col-md-12 col-xs-12 text-center'>
              <Link
                to='/contact/'
                className='button button-primary'
              >
                <span>WANT TO WORK TOGETHER?</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
