import React from "react"

import Startups from "../../../images/HowWeWork/ClientsSection/startups.svg"
import Business from "../../../images/HowWeWork/ClientsSection/business.svg"
import Outsource from "../../../images/HowWeWork/ClientsSection/outsource.svg"

import "./ClientsSection.scss"

const clients = [
  {
    id: 1,
    image: Startups,
    title: "Startups",
    article:
      "They have an idea and need technical expertise to build a scalable solution.",
  },
  {
    id: 2,
    image: Business,
    title: "Small & medium business",
    article:
      "We automate existing operations or provide extra pair of hands to handle IT operations.",
  },
  {
    id: 3,
    image: Outsource,
    title: "Large IT outsourcers",
    article:
      "We pick up projects where a smaller and more flexible team would be best.",
  },
]

export default class ClientsSection extends React.Component {
  render() {
    return (
      <section className="company-clients-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-6 col-xs-8">
              <h3>Our Clients</h3>
              <p>
                We successfully providing three types of companies with software
                solutions
              </p>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="clients-container">
                {clients.map(client => (
                  <div key={client.id} className="clients-block">
                    <img src={client.image} alt="client" />
                    <div>
                      <h4>{client.title}</h4>
                      <p>{client.article}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
